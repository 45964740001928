define("discourse/plugins/discourse-global-filter/discourse/templates/connectors/discovery-list-container-top/subcategory-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.display_subcategories_for_tag_intersection}}
    <GlobalFilter::SubcategoriesDisplay
      @subcategories={{this.category.subcategories}}
    />
  {{/if}}
  */
  {
    "id": "h/Sxidx6",
    "block": "[[[41,[30,0,[\"siteSettings\",\"display_subcategories_for_tag_intersection\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@subcategories\"],[[30,0,[\"category\",\"subcategories\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"global-filter/subcategories-display\"]]",
    "moduleName": "discourse/plugins/discourse-global-filter/discourse/templates/connectors/discovery-list-container-top/subcategory-list.hbs",
    "isStrictMode": false
  });
});