define("discourse/plugins/discourse-global-filter/discourse/components/global-filter/filtered-tags-chooser", ["exports", "select-kit/components/tag-drop", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _tagDrop, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FilteredTagsChooser = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <li class="filtered-tag-drop">
      <TagDrop
        @currentCategory={{@category}}
        @noSubcategories={{@currentCategory.subcategory_ids}}
        @tagId={{null}}
      />
    </li>
  
  */
  {
    "id": "azUC9Ocu",
    "block": "[[[1,\"\\n  \"],[10,\"li\"],[14,0,\"filtered-tag-drop\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@currentCategory\",\"@noSubcategories\",\"@tagId\"],[[30,1],[30,2,[\"subcategory_ids\"]],null]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@category\",\"@currentCategory\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-filter/discourse/components/global-filter/filtered-tags-chooser.js",
    "scope": () => [_tagDrop.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = FilteredTagsChooser;
});