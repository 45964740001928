define("discourse/plugins/discourse-global-filter/discourse/components/global-filter/subcategories-display", ["exports", "@glimmer/component", "@glimmer/tracking", "discourse/components/categories-only", "discourse/lib/ajax", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _categoriesOnly, _ajax, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubcategoriesDisplay extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "filteredSubcategories", [_tracking.tracked]))();
    #filteredSubcategories = (() => (dt7948.i(this, "filteredSubcategories"), void 0))();
    constructor() {
      super(...arguments);
      this.loadSubcategories();
    }
    async loadSubcategories() {
      if (!this.args.subcategories) {
        return;
      }
      this.filteredSubcategories = await (0, _ajax.ajax)(`/global_filter/filter_tags/categories_for_current_filter.json`).then(model1 => {
        const subcategoriesByName1 = this.args.subcategories.map(item1 => item1.name);
        return model1.subcategories.filter(subcategory1 => subcategoriesByName1.includes(subcategory1.name));
      });
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="filtered-subcategories">
          <CategoriesOnly
            @categories={{this.filteredSubcategories}}
            @showTopics={{false}}
          />
        </div>
      
    */
    {
      "id": "wPGw106x",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"filtered-subcategories\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@categories\",\"@showTopics\"],[[30,0,[\"filteredSubcategories\"]],false]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-filter/discourse/components/global-filter/subcategories-display.js",
      "scope": () => [_categoriesOnly.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SubcategoriesDisplay;
});